.sliderWrapper {
  .sliderContainer {
    overflow: hidden;

    .slide.hide {
      display: none;
    }

    .slide {
      .imageContainer {
        img {
          object-fit: contain;
        }
      }
    }
  }

  .sliderThumbnails {
    margin-top: 15px;
    display: flex;
    justify-content: center;

    .thumbnail {
      width: 50px; height: 50px; border: 1px solid gray; padding: 10px; box-sizing: border-box; border-right: 0;
       img {
         width: 100%; object-fit: contain;
       }
    }

    .thumbnail:last-of-type {
      border-right: 1px solid gray;
    }
  }
}