@use 'assets/scss/mixins.scss' as mixins;

.cbc-wizard.nav {

    border-bottom: 3px solid;
    @include mixins.theme('border-color', 'ui-secondary-color');
    flex-wrap: initial;

    .nav-item:last-child {
        margin-right: 0;
    }

    .nav-item {
        @include mixins.theme('background', 'ui-primary-color');
        border-radius: 10px;
        color: #fff;
        font-weight: 800;
        font-size: .90em;
        padding: 5px 15px;
        margin-right: 5px;
        margin-bottom: 5px;
        border: 0;
        display: flex;
        justify-content: center;

        .tab-title {flex: 1;}

        .tab-errors {
            background: #C92434;
            color: white;
            font-weight: 500;
            height: 20px;
            border-radius: 6px;
            padding: 5px;
            line-height: 9px;
            margin-right: -10px;
            box-shadow: 2px 2px 6px -3px #000000;
            font-size: 12px;
        }
    }

    .nav-item.active {
        @include mixins.theme('background', 'ui-secondary-color', 1);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        color: #fff;
        margin-bottom: -1px;
    }
}

.cbc-wizard.no-border {
    border-bottom: 0;

    .nav-item.active {
        border-bottom: 1px solid white !important;
    }
}

.cbc-wizard-content {
    padding: 15px 40px;

    .section-hidden {
        display: none;
    }
}

.cbc-wizard-navigation {
    display: flex;
    align-items: center;


    @mixin button-wizard-nav {
        @include mixins.theme('background', 'ui-secondary-color');
        font-weight: 500;
        font-size: .80em;
        border-radius: 19px;
        padding: 1px 2px;
        min-width: 100px;
        height: 27px;

        img {
            width: 20px;
        }
    }

    .previous {
        flex: 1;
        text-align: left;

        button:hover, button:focus, button:active {
            @include mixins.theme('background', 'ui-primary-color');
        }

        button {
            text-align: left;
            @include button-wizard-nav;

            img {
                margin-right: 1em;
            }
        }
    }

    .next {
        flex: 1;
        text-align: right;

        button:hover, button:focus, button:active {
            @include mixins.theme('background', 'ui-primary-color');
        }

        button {
            text-align: right;
            @include button-wizard-nav;

            img {
                margin-left: 1em;
            }
        }
    }

    .dots {
        flex: 2;
        text-align: center;
    }

    .dot.active {
        @include mixins.theme('background', 'ui-secondary-color');
    }
    .dot {
        width: 14px;
        height: 14px;
        border: 1px solid;
        @include mixins.theme('border-color', 'ui-secondary-color');
        box-sizing: border-box;
        margin: 0 2px;
        display: inline-block;
        border-radius: 14px;
    }
}

@media (max-width: 695px) {
    .cbc-wizard-content {
        padding: 15px 0;
    }

    .cbc-wizard-title {
        border-bottom: 2px solid;
        @include mixins.theme('border-color', 'ui-secondary-color');

        span {
            @include mixins.theme('background', 'ui-secondary-color');
            width: max-content;
            display: block;
            border-radius: 0 8px 0 0;
            box-sizing: border-box;
            color: white;
            font-weight: bold;
            font-size: 1em;
            text-align: center;
            padding: 3px 15px 5px;
            margin-bottom: -2px;
        }
    }
}

@media (max-width: 1050px) {
    .cbc-wizard-content {
        padding: 15px;
    }
}
