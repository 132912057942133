@use 'assets/scss/mixins.scss' as mixins;

.cbc-table-cursor:not(.qfp-table) tbody tr:hover {
    cursor: pointer;
    @include mixins.theme('background', 'ui-secondary-color-table-hover', 1);
    td {
        @include mixins.theme('background', 'ui-secondary-color-table-hover', 1);
    }
}
.cbc-table:not(.qfp-table) tbody tr:hover {
    @include mixins.theme('background', 'ui-secondary-color-table-hover', 1);
    td {
        @include mixins.theme('background', 'ui-secondary-color-table-hover', 1);
    }
}

.word-break-cbc-table {
    font-size: 0.7rem;

    thead {
        @include mixins.theme('background', 'ui-secondary-color');
        border-radius: 0 10px 0 0;
        tr {
            th:last-child {
                border-radius: 0 10px 0 0;
            }
            th {
                color: #fff;
                box-sizing: border-box;
                padding: 5px 0;
                text-align: center;
                border: 0;
                vertical-align: middle;
                background-color: inherit;
            }
        }
    }

    tbody {
        tr {
            td {
                @include mixins.theme('color', 'ui-table-text-color');
                border: 0;
                padding: 5px;
                text-align: center;
                vertical-align: middle;
                box-sizing: border-box;
                height: 30px;
                word-break: break-word;
                background: inherit;
            }

            td:nth-child(2) {
                //text-align: left;
                padding: 5px 15px;
            }

            td:nth-child(even) {
                @include mixins.theme(
                    'background-color',
                    'ui-primary-color-transparent-2'
                );
            }

            td:last-child {
                button {
                    padding: 0;
                    margin: 0;
                    width: 20px;
                    height: 20px;
                    text-align: center;
                    box-sizing: border-box;
                    margin: 0 1px;
                    border: 0;

                    img {
                        width: 20px;
                        vertical-align: initial;
                    }
                }
            }
        }

        tr.even {
            @include mixins.theme('background', 'ui-tr-even-background');

            td:nth-child(even) {
                background: rgba(244, 249, 252, 0.3);
            }
        }

        tr.odd {
            @include mixins.theme('background', 'ui-tr-odd-background');
        }

        .table-option-dropdown {
            display: inline-block;

            .dropdown-toggle::after,
            .dropdown-toggle::before {
                display: none;
            }

            .dropdown-menu {
                padding: 0;
                border: 2px solid white;
                border-radius: 8px 8px 0 8px;
                .dropdown-item {
                    font-size: 0.85em;
                    color: white;
                    @include mixins.theme('background', 'ui-stripe-first-color');
                }
                .dropdown-item:nth-of-type(even) {
                    @include mixins.theme('background', 'ui-stripe-second-color');
                }
                .dropdown-item:first-of-type {
                    border-radius: 8px 8px 0 0;
                }
                .dropdown-item:last-of-type {
                    border-radius: 0 0 0 8px;
                }
            }
        }
    }
}

.cbc-table,
.cbc-table-cursor {
    font-size: 0.7rem;

    thead {
        @include mixins.theme('background', 'ui-secondary-color');
        border-radius: 0 10px 0 0;
        tr {
            th:last-child {
                border-radius: 0 10px 0 0;
            }
            th {
                color: #fff;
                box-sizing: border-box;
                padding: 5px 0;
                text-align: center;
                border: 0;
                vertical-align: middle;
                background-color: inherit;
            }
        }
    }

    tbody {
        tr {
            td {
                @include mixins.theme('color', 'ui-table-text-color');
                border: 0;
                padding: 5px;
                text-align: center;
                vertical-align: middle;
                box-sizing: border-box;
                height: 30px;
                background: inherit;
            }

            td:nth-child(2) {
                //text-align: left;
                padding: 5px 15px;
            }

            td:nth-child(even) {
                @include mixins.theme(
                    'background-color',
                    'ui-primary-color-transparent-2'
                );
            }

            td:last-child {
                button {
                    padding: 0;
                    margin: 0;
                    width: 20px;
                    height: 20px;
                    text-align: center;
                    box-sizing: border-box;
                    margin: 0 1px;
                    border: 0;

                    img {
                        width: 20px;
                        vertical-align: initial;
                    }
                }
            }
        }

        tr.even {
            @include mixins.theme('background', 'ui-tr-even-background');

            td:nth-child(even) {
                background: rgba(244, 249, 252, 0.3);
            }
        }

        tr.odd {
            @include mixins.theme('background', 'ui-tr-odd-background');
        }

        .table-option-dropdown {
            display: inline-block;

            .dropdown-toggle::after,
            .dropdown-toggle::before {
                display: none;
            }

            .dropdown-menu {
                padding: 0;
                border: 2px solid white;
                border-radius: 8px 8px 0 8px;
                .dropdown-item {
                    font-size: 0.85em;
                    color: white;
                    @include mixins.theme('background', 'ui-stripe-first-color');
                }
                .dropdown-item:nth-of-type(even) {
                    @include mixins.theme('background', 'ui-stripe-second-color');
                }
                .dropdown-item:first-of-type {
                    border-radius: 8px 8px 0 0;
                }
                .dropdown-item:last-of-type {
                    border-radius: 0 0 0 8px;
                }
            }
        }
    }
}

.pagination {
    .page-item {
        .page-link {
            @include mixins.theme('color', 'ui-secondary-color');
        }
    }

    .page-item.active {
        .page-link {
            @include mixins.theme('border-color', 'ui-secondary-color');
            color: #fff;
            @include mixins.theme('background', 'ui-secondary-color');
        }
    }

    .page-item:last-child {
        .page-link {
            border-radius: 0 0.2rem 0 0;
        }
    }
}

@media (max-width: 695px) {
    .mobile-menu-table {
        background: #fff;
        border-bottom: 2px solid;
        @include mixins.theme('border-color', 'ui-primary-color');

        button {
            width: 40px !important;
            height: 40px !important;
            margin: 0 6px !important;

            img {
                width: 40px !important;
            }
        }
    }

    .cbc-table {
        thead {
            border-radius: 0 !important;
            tr {
                th:last-child {
                    border-radius: 0 !important;
                }
            }
        }

        tbody tr td:last-child {
            .mobile-lone-button {
                width: 25px !important;
                height: 25px !important;

                img {
                    width: 25px !important;
                }
            }
        }
    }

    .pagination {
        margin-left: 15px;
    }
}

.table-head-inherit-bg {
    thead {
        background: inherit !important;
    }
}
